import { OrgManageService } from '@/sevices';
const Organization = {
    namespaced: true,
    state: {
        orgCount: 0,
        enableOrgNum: 0,
        disableOrgNum: 0,
        checkOrgLoading: false,
    },
    mutations: {
        setOrgCount(state, data) {
            state.orgCount = data;
        },
        setEnableOrgCount(state, data) {
            state.enableOrgNum = data;
        },
        setDisableOrgCount(state, data) {
            state.disableOrgNum = data;
        },
        setCheckOrgLoading(state, data) {
            state.checkOrgLoading = data;
        },
    },
    actions: {
        checkOrg({ commit }, checkOrgLoading = true) {
            commit('setCheckOrgLoading', checkOrgLoading);
            return OrgManageService.getOrgCheck().then((res) => {
                if (res.success) {
                    const enableNum = res.data?.enableNum;
                    const disableNum = res.data?.disableNum;
                    const orgCount = enableNum + disableNum;
                    commit('setOrgCount', orgCount);
                    commit('setEnableOrgCount', enableNum);
                    commit('setDisableOrgCount', disableNum);
                }
            }).finally(() => {
                commit('setCheckOrgLoading', false);
            });
        },
        resetVuex({ commit }) {
            commit('setOrgCount', 0);
            commit('setEnableOrgCount', 0);
            commit('setDisableOrgCount', 0);
        },
    },
    getters: {},
};
export default Organization;

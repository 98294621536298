export default [
    {
        path: '/m-work-platform',
        name: 'm-work-platform',
        meta: { isWorkPlatform: true, keepAlive: false },
        component: () => import('@/views/m-work-platform/index.vue'),
        children: [
            {
                path: '/',
                redirect: { name: 'm-work-platform-home' },
            },
            {
                path: '/m-work-platform-login',
                name: 'm-work-platform-login',
                component: () => import('@/views/m-work-platform/setting/login.vue'),
                meta: {
                    noShowHeader: true,
                    showTabBar: false,
                    isWorkPlatform: true,
                },
            },
            {
                path: '/m-work-platform-home',
                name: 'm-work-platform-home',
                component: () => import('@/views/m-work-platform/home/index.vue'),
                meta: {
                    noShowHeader: true,
                    showTabBar: true,
                    isWorkPlatform: true,
                    keepAlive: false,
                },
            },
            {
                path: '/m-work-platform-home-search-app',
                name: 'm-work-platform-home-search-app',
                component: () => import('@/views/m-work-platform/home/searchApp.vue'),
                meta: {
                    noShowHeader: true,
                    showTabBar: false,
                    isWorkPlatform: true,
                },
            },
            {
                path: '/m-work-platform-notices',
                name: 'm-work-platform-notices',
                component: () => import('@/views/m-work-platform/notices/index.vue'),
                meta: {
                    noShowHeader: true,
                    showTabBar: true,
                    isWorkPlatform: true,
                },
            },
            {
                path: '/m-work-platform-setting',
                name: 'm-work-platform-setting',
                component: () => import('@/views/m-work-platform/setting/index.vue'),
                meta: {
                    noShowHeader: true,
                    showTabBar: true,
                    isWorkPlatform: true,
                },
            },
            {
                path: '/m-work-platform-switch-team',
                name: 'm-work-platform-switch-team',
                component: () => import('@/views/m-work-platform/setting/switch-team.vue'),
                meta: {
                    noShowHeader: true,
                    showTabBar: false,
                    isWorkPlatform: true,
                },
            },
            {
                path: '/m-work-platform-change-password',
                name: 'm-work-platform-change-password',
                component: () => import('@/views/m-work-platform/setting/change-password.vue'),
                meta: {
                    noShowHeader: true,
                    showTabBar: false,
                    isWorkPlatform: true,
                },
            },
        ],
    },
];

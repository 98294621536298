import API, { commonErrHandler } from './config';
export class LogService {
    static async getLoginLogList(params) {
        const res = await API.Global.request({
            url: '/api/operationadmin/user_login_log/query',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async getLogAppList(params) {
        const res = await API.Global.request({
            url: '/api/operationadmin/app_logs/apps',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async getOpeateLogList(params) {
        const res = await API.Global.request({
            url: '/api/operationadmin/operate_logs',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async getAppManageLogList(params) {
        const res = await API.Global.request({
            url: '/api/operationadmin/app_logs',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
}

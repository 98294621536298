const workPlatform = {
    namespaced: true,
    state: {
        onlineApps: [],
        recentIds: [],
        recentApps: [],
        fromLocal: false,
        storageKey: null,
        isSetOrg: false,
    },
    mutations: {
        updateIsSetOrg(state, val) {
            state.isSetOrg = val;
        },
        updateFromLocal(state, append) {
            state.fromLocal = append;
        },
        updateOnlineApps(state, append) {
            state.onlineApps = append;
        },
        updateRecentIds(state, append) {
            if (append && Array.isArray(append)) {
                const key = state.storageKey;
                localStorage.setItem(key, JSON.stringify(append));
            }
            state.recentIds = append;
            console.log('state.recentIds', state.recentIds);
        },
        updateStorageKey(state) {
            if (!state.storageKey) {
                let publicUserInfo = localStorage.getItem('publicUserInfo');
                if (publicUserInfo &&
                    typeof publicUserInfo === 'string' &&
                    publicUserInfo !== 'undefined') {
                    publicUserInfo = JSON.parse(publicUserInfo) || {};
                    const userInfo = publicUserInfo || {};
                    const userName = userInfo.name;
                    const key = `RECENT_APP_IDS--${userName}`;
                    state.storageKey = key;
                }
            }
        },
        updateRecentApps(state) {
            const { onlineApps, recentIds } = state;
            const apps = [];
            if (onlineApps.length && recentIds.length) {
                onlineApps.forEach((item) => {
                    const order = recentIds.indexOf(item.id);
                    if (order > -1) {
                        apps.push({ ...item, order });
                    }
                });
                apps.sort((a, b) => a.order - b.order);
                state.recentApps = apps;
            }
            // state.recentApps = apps;
        },
        resetWorkPlatform(state) {
            state.onlineApps = [];
            state.recentIds = [];
            state.recentApps = [];
            state.fromLocal = false;
            state.storageKey = null;
            state.isSetOrg = false;
        },
    },
    actions: {
        setOlineApps({ commit, state }, payload) {
            commit('updateOnlineApps', payload);
            if (!state.fromLocal) {
                commit('updateStorageKey');
                const key = state.storageKey;
                const recentAppStr = localStorage.getItem(key);
                if (typeof recentAppStr === 'string') {
                    const recentApps = JSON.parse(recentAppStr);
                    commit('updateRecentIds', recentApps);
                }
                commit('updateFromLocal', true);
            }
            commit('updateRecentApps');
        },
        setRecentIds({ commit }, payload) {
            commit('updateStorageKey');
            commit('updateRecentIds', payload);
            commit('updateRecentApps');
        },
    },
    getters: {},
};
export default workPlatform;

export default {
    // 组织架构
    organization: 'org-user',
    // 平台账号
    inServiceStaff: 'user-manage',
    // 组织架构管理
    organizationManage: 'org-manage',
    // 组织架构部门管理
    orgDeptManage: 'org-department-manage',
    // 组织架构人员管理
    orgUserManage: 'org-user-manage',
    // 离职人员
    exServingOfficer: 'ex-serving-officer',
    // 标签管理(菜单栏目)
    labelManage: 'org-tag-manage',
    // 通用标签
    labels: 'org-tag-manage-common',
    // 组织专用标签
    specialUseLabel: 'org-tag-manage-organization',
    // 通用标签的人员和部门管理
    orgTagManageCommonUserDept: 'org-tag-manage-common-user-dept',
    // 组织专用标签的人员和部门管理
    orgTagManageOrganizationUserDept: 'org-tag-manage-organization-user-dept',
};

export default {
  // 应用管理
  appManage: 'app-manage',

  // 上下线
  onlineAndOffline: 'app-manage-up-or-down',

  // 删除角色
  delRole: 'app-manage-role-manage-delete',

  // 分配角色
  distribute: 'auth-config-set',

  // 添加角色
  addRole: 'app-manage-role-manage-add',

  // 修改角色权限
  roleManageEdit: 'app-manage-role-manage-edit',
};

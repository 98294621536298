import API from '@cloudpivot-shared/domain-api';
API.Global.useLogger();
export default API;
/**
 * 普通业务处理
 */
export function commonErrHandler(res, isSendData = false, repeatErrorCode = []) {
    const { success, errCode, errMessage, data, total, } = res;
    if (success) {
        if (!isSendData) {
            return { success, errMessage, data };
        }
        else {
            return { success, data, total };
        }
    }
    else {
        let sendError = '';
        if (repeatErrorCode.includes(errCode)) {
            sendError = JSON.stringify({ errCode, errMessage });
        }
        else {
            sendError = errMessage;
        }
        throw sendError;
    }
}
/**
 * 导出处理
 */
export function exportHandler(res) {
    const response = res.orgResponse;
    if (response) {
        if (response.status === 200) {
            return {
                success: true,
                data: response.data,
            };
        }
        else {
            return {
                success: false,
                errMessage: response.statusText,
            };
        }
    }
    return {
        success: false,
        errMessage: '导出异常！',
    };
}

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Header from './components/global/header/index.vue';
import zhCN from '@h3/antd-vue/lib/locale-provider/zh_CN';
// import enUS from '@h3/antd-vue/lib/locale-provider/en_US';
import { IS_DINGTALK, IS_WECHAT, } from '@cloudpivot-hermes/nail-free-login';
import { namespace } from 'vuex-class';
const Global = namespace('Global');
const Organization = namespace('Organization');
let Apps = class Apps extends Vue {
    constructor() {
        super(...arguments);
        this.switchHeader = false;
        this.locale = zhCN;
    }
    get noShowHeader() {
        return this.$route.meta && this.$route.meta.noShowHeader;
    }
    // 获取配置文件
    async mounted() {
        // 获取配置文件
    }
    beforeDestroy() {
        window.removeEventListener('message', () => { });
    }
    get isFreeLogin() {
        return IS_DINGTALK || IS_WECHAT || localStorage.IS_FREELOGIN;
    }
    async created() {
        this.reloadPermData();
        window.addEventListener('message', this.reloadPage, false);
    }
    async reloadPermData() {
        const token = localStorage.getItem('token');
        // const isFreeLogin: any = localStorage.IS_FREELOGIN;
        if (token) {
            this.checkOrg();
            await this.getAllPerms();
            localStorage.removeItem('IS_FREELOGIN');
        }
    }
    /**
     * 如果流程发生变化，则刷新数据
     */
    async reloadPage(evt) {
        const isFreeLogin = localStorage.IS_FREELOGIN;
        if (evt.data === 'reload' && evt.origin === window.location.origin && isFreeLogin) {
            location.reload();
        }
    }
};
__decorate([
    Global.Action('getAllPerms')
], Apps.prototype, "getAllPerms", void 0);
__decorate([
    Organization.Action('checkOrg')
], Apps.prototype, "checkOrg", void 0);
Apps = __decorate([
    Component({
        name: 'apps',
        components: {
            Header,
        },
    })
], Apps);
export default Apps;

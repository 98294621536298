import 'babel-polyfill';
import store from '@/store';
import API from '@cloudpivot-shared/domain-api';
import { OrgManageService } from '@/sevices';
import { dingTalkInit, IS_DINGTALK, IS_WECHAT, service, IS_IOS, dd, } from '@cloudpivot-hermes/nail-free-login';
// import { i18n } from "@/config/i18n";
import './apis/interceptors';
// import { IconFont } from '@cloudpivot-shared/property-editor';
import { isPC } from '@/common/utils';
// import Vue from 'vue'
import cookie from 'js-cookie';
import { formatter, startup } from './startup';
window.$cookie = cookie;
window.dd = dd;
// import { H3Toast, H3Modal } from '@h3/thinking-ui';
// const VConsole = require('vconsole');
// eslint-disable-next-line no-new
// new VConsole();
// 二次开发时，可以自行引入自定义的图标库,并全局挂载
// const baseUrl = process.env.BASE_URL;
// const iconfontFile = 'iconfont.min.js';
// IconFont.loadingIconFont(
//   Vue,
//   baseUrl ? baseUrl + '/' + iconfontFile : iconfontFile,
// );
API.ErrorCatcher.create({
    dev: true,
    duration: 3000,
});
// Vue.use(H3Toast);
// Vue.use(H3Modal);
// 是否是免登url传参
function isFreeLogin() {
    return parseQuery(location.href).token;
}
const setInfoToLocal = (data, originalData) => {
    const userInfo = {
        name: data ? data.name : '-',
        username: data ? data.username : '-',
        mobile: data.mobile || '-',
        userId: data.userId,
        baseUserId: data.baseUserId,
        imgUrl: data.imgUrl,
    };
    const publicUserInfo = userInfo;
    localStorage.setItem('publicUserInfo', JSON.stringify(publicUserInfo));
    localStorage.setItem('corpInfo', JSON.stringify(originalData));
    // localStorage.removeItem('publicInfo')
};
async function setUserInfo() {
    const res = await OrgManageService.getLoginInfo();
    if (res.success) {
        const data = res.data || {};
        const publicUserInfo = {
            name: data.name,
            username: data.username,
            mobile: data.mobile,
            userId: data.id,
            baseUserId: data.baseUserId,
            imgUrl: data.imgUrl,
        };
        setInfoToLocal(publicUserInfo, data);
    }
}
async function openMessage(messageId) {
    API.Global.request({
        url: '/api/common/get_message_form_url',
        method: 'get',
        params: { messageId },
    }).then(res => {
        if (res.data && res.data.targetUrl) {
            const messageIds = parseQuery(res.data.targetUrl).messageId;
            console.log('parseQuery', parseQuery(res.data.targetUrl).messageId);
            if (messageIds) {
                getMessageUrl({ messageId: messageIds, targetUrl: res.data.targetUrl });
            }
            else {
                init(res.data.targetUrl, messageId);
            }
        }
    });
}
function parseQuery(url) {
    const vars = {};
    let hash;
    let urls = decodeURI(url).replace(window.location.origin, '');
    const jhIndex = urls.indexOf('#');
    if (jhIndex > -1) {
        urls = urls.substr(jhIndex);
    }
    const index = urls.split('?');
    let hashes = [];
    if (index.length > 1) {
        hashes = index[1].split('&');
        for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars[hash[0]] = decodeURIComponent(hash[1]);
        }
    }
    return vars;
}
/**
 * 获取message地址
 */
function getMessageUrl(params) {
    API.Global.request({
        url: '/api/todo/todo/v1/get_single_todo',
        method: 'get',
        params: { messageId: params.messageId },
    }).then(res => {
        if (res.success) {
            const result = res.data;
            const token = localStorage.getItem('token');
            const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
            const pageData = formatter(result);
            const freetype = IS_WECHAT ? 'wei' : 'dingtalk';
            // eslint-disable-next-line max-len
            const url = `${params.targetUrl.split('/home/')[0]}/home/${result.appCode}?workItemId=${result.id}&workflowInstanceId=${result.instanceId}&appVersion=${result.appVersion}&appCode=${pageData.appCode}&groupCode=${pageData.groupCode}&moduleCode=${pageData.moduleCode}&pageGroupCode=${pageData.pageGroupCode}&pageCode=${pageData.pageCode}&pageType=${pageData.pageType}&token=${token}&userInfo=${userInfo}&freelogin=${freetype}&from=message`;
            cookie.set('queryMsg', JSON.stringify({
                messageId: params.messageId,
                targetUrl: url,
            }), {
                expires: new Date(Number(localStorage.getItem('expireTime'))),
            });
            if (IS_DINGTALK || IS_WECHAT) {
                blankTips(url);
            }
        }
    });
}
function toPage(url) {
    if (IS_DINGTALK || IS_WECHAT) {
        blankTips(url);
    }
}
async function init(targetUrl, messageId) {
    const token = localStorage.getItem('token');
    const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
    const freetype = IS_WECHAT ? 'wei' : 'dingtalk';
    // eslint-disable-next-line max-len
    const url = `${targetUrl.replace('/#', '/?#')}&token=${token}&userInfo=${userInfo}&freelogin=${freetype}&from=message`;
    cookie.set('queryMsg', JSON.stringify({
        messageId,
        targetUrl: url,
    }), {
        expires: new Date(Number(localStorage.getItem('expireTime'))),
    });
    if (IS_DINGTALK || IS_WECHAT) {
        blankTips(url);
    }
}
function blankTips(url) {
    const $app = document.getElementById('app');
    if (!$app) {
        throw new Error('can\'t find #app');
    }
    $app.innerText = '';
    $app.style.textAlign = 'center';
    if (isPC()) {
        $app.style.paddingTop = '130px';
        $app.style.color = '#666';
        $app.style.fontSize = '22px';
    }
    else {
        $app.style.paddingTop = '50px';
        $app.style.color = '#666';
        $app.style.fontSize = '12px';
    }
    $app.style.position = 'relative';
    $app.style.width = '100%';
    $app.style.height = '100%';
    const $a = document.createElement('a');
    $a.style.textDecoration = 'underline';
    $a.href = url;
    $a.id = 'jump_url';
    $a.target = '_blank';
    $a.innerText = '页面跳转中...请稍后...';
    // '已经在外部浏览器打开该页面'
    $app.appendChild($a);
    // $a.click();
    jumpUrl(url);
}
// 微信钉钉页面跳转
function jumpUrl(url) {
    const isMobile = /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
    if (IS_WECHAT) {
        console.log('wechat', url, isMobile);
        try {
            if (isMobile) {
                setTimeout(() => {
                    // 待办菜单移动端
                    if (parseQuery(location.href).public_blank_open === 'true') {
                        window.location.href = url.split('?')[0];
                        location.reload();
                    }
                    else {
                        window.location.href = url;
                    }
                    // service.openLink(url);
                }, 300);
            }
            else {
                service.openLink(url);
            }
        }
        catch (err) {
            console.log('err', err);
        }
    }
    else {
        try {
            if (!isPC()) {
                window.location.replace(url);
            }
            else {
                service.openLink(url);
            }
        }
        catch (err) {
            console.log('err', err);
        }
    }
}
// 配置待办菜单 在浏览器打开 工作台
async function openPublicBlank() {
    const locationHasOterParam = location.href.split('?corpId').length === 1;
    const locationOrigin = locationHasOterParam
        ? location.href.split('&corpId')[0] : location.href.split('?corpId')[0];
    const token = localStorage.getItem('token');
    const locationOpen = locationHasOterParam
        ? `${locationOrigin}&token=${token}` : `${locationOrigin}?token=${token}`;
    blankTips(locationOpen);
}
async function start() {
    try {
        const result = await dingTalkInit();
        if (!result)
            return;
        const { query } = result;
        sessionStorage.IS_DINGTALK = IS_DINGTALK;
        sessionStorage.IS_WECHAT = IS_WECHAT;
        if (isFreeLogin()) {
            const token = parseQuery(location.href).token;
            localStorage.setItem('token', token);
            await setUserInfo();
            await store.dispatch('Global/getAllPerms');
        }
        if (IS_DINGTALK || IS_WECHAT) {
            await store.dispatch('Global/getAllPerms');
            await setUserInfo();
        }
        ;
        if (query.messageId) {
            const queryMsg = JSON.parse(cookie.get('queryMsg') || '{}');
            if (cookie.get('queryMsg') && queryMsg?.messageId === query.messageId) {
                if (IS_DINGTALK || IS_WECHAT) {
                    if (isPC()) {
                        toPage(queryMsg?.targetUrl);
                    }
                    else {
                        if (IS_IOS) {
                            openMessage(query.messageId);
                        }
                        else {
                            toPage(queryMsg?.targetUrl);
                        }
                    }
                }
            }
            else {
                openMessage(query.messageId);
            }
        }
        else if (query.public_blank_open) {
            openPublicBlank();
        }
        else {
            startup();
        }
    }
    catch (error) {
        startup();
        console.log('startError', error);
    }
}
// mount();
start();

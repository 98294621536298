import API, { commonErrHandler } from './config';
const prefix = '/api/commonservice';
/**
 *  权限管理
 */
export class PermManageService {
    /**
     * 获取角色列表
    */
    static async getRoleList(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/search`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 更改角色信息状态
    */
    static async enableRole(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/on_off`,
            method: 'put',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 删除角色信息
    */
    static async delRole(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/${params.id}`,
            method: 'delete',
        });
        return commonErrHandler(res, true);
    }
    /**
     * 重置角色信息
     */
    static async resetRole(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/reset`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取角色信息
     */
    static async getRoleDetail(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/${params.id}`,
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
    /**
     * 添加角色信息
     */
    static async addRole(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 更新角色信息
     */
    static async updateRole(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/${params.id}`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取权限数据
     */
    static async getAllRolePerm(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/all_custom_perm`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 登录时获取权限数据
     */
    static async getAllPerms(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/perms`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 管理员列表
    */
    static async getManagerList(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/user/search`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 删除管理员
    */
    static async delManager(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/user/${params.id}`,
            method: 'delete',
        });
        return commonErrHandler(res, true);
    }
    /**
     * 新建管理员
    */
    static async addManager(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/user`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 编辑管理员
    */
    static async updateManager(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/user`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 停用启用管理员
    */
    static async enableManager(params) {
        const res = await API.Global.request({
            url: `${prefix}/platform/role/user/on_off`,
            method: 'put',
            params,
        });
        return commonErrHandler(res, true);
    }
}

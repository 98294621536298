import API, { commonErrHandler } from './config';
export class FlowService {
    static async getFlowInstanceList(params) {
        const res = await API.Global.request({
            url: '/api/todo/workflow/v1/search',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取流程模板
     */
    static async getWorkflowTree(params) {
        const res = await API.Global.request({
            url: '/api/todo/workflow/v1/work_flows',
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    static async getFlowEntrustList(params) {
        const res = await API.Global.request({
            url: '/api/todo/v1/workflow/agency_entrust',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async addEntrust(params) {
        const res = await API.Global.request({
            url: '/api/todo/v1/workflow/agency_entrust',
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async updateEntrust(params) {
        const res = await API.Global.request({
            url: '/api/todo/v1/workflow/agency_entrust',
            method: 'put',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async delEntrust(params) {
        const res = await API.Global.request({
            url: '/api/todo/v1/workflow/agency_entrust',
            method: 'delete',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async getEntrustInfo(params) {
        const res = await API.Global.request({
            url: '/api/todo/v1/workflow/agency_entrust',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async getAppList(params) {
        const res = await API.Global.request({
            url: '/api/todo/v1/workflow/agency_entrust/apps',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async getGroupList(params) {
        const res = await API.Global.request({
            url: `/api/metadata/groups/${params.appCode}`,
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
    static async getFlowList(params) {
        const res = await API.Global.request({
            url: '/api/metadata/runtime/workflow/list',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async getPageJson(params) {
        const res = await API.Global.request({
            url: `/api/launcher/${params.appCode}/application/flow/work_item/load_context`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async getExceptionList(params) {
        const res = await API.Global.request({
            url: '/api/todo/workflow/exception/list',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async getExceptionDetail(params) {
        const res = await API.Global.request({
            url: '/api/todo/workflow/exception/getById',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    static async fixExpLog(params) {
        const res = await API.Global.request({
            url: `/api/launcher/${params.appCode}/application/instance/fix_exp_log`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true, ['100000']);
    }
}

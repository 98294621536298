import API, { commonErrHandler } from './config';
const prefix = '/api/commonservice';
export class LabelService {
    /*
    页面加载第一次获取标签树结构
    */
    static async getLabelTree(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/search`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /*
    标签搜索
    */
    static async searchLabel(params, options = {}) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/search_by_name`,
            method: 'get',
            params,
            ...options,
        });
        return commonErrHandler(res, true);
    }
    /*
    根据id获取树结构下级节点列表
    */
    static async getLabelchildren(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/list_by_id`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /*
    根据id查询部门/人员表格数据
    */
    static async getTableListById(params, options = {}) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/${params.id}/unit`,
            method: 'get',
            params,
            ...options,
        });
        return commonErrHandler(res, true);
    }
    /*
    查询组织/分组
    */
    static async getOrgAndGroup(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/list_by_choice`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /*
    新增标签/分组
    */
    static async addLabelOrGroup(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    /*
    编辑标签/分组
    */
    static async upDateLabelOrGroup(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    /*
    删除标签/分组
    */
    static async delLabelOrGroup(id) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/${id}`,
            method: 'delete',
        });
        return commonErrHandler(res, true);
    }
    /*
    获取标签/分组详情
    */
    static async getLabelOrGroupDetail(id, options = {}) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/${id}`,
            method: 'get',
            ...options,
        });
        return commonErrHandler(res, true);
    }
    /*
    删除部门/人员
    */
    static async delDeptAndStaff(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/unit`,
            method: 'delete',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    /*
    添加部门/人员
    */
    static async addUser(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/${params.labelId}/unit`,
            method: 'post',
            data: params.data,
        });
        return commonErrHandler(res, true);
    }
    /*
    *设置管理范围
    */
    static async setScope(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/label/unit/${params.labelUserId}/scope`,
            method: 'post',
            data: params.data,
        });
        return commonErrHandler(res, true);
    }
}

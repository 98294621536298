import API, { commonErrHandler } from './config';
export class FlowCenterService {
    /**
     * 获取流程模板
     */
    static async getFlowTemplate(params) {
        const res = await API.Global.request({
            url: '/api/todo/todo/v1/get_allworkflow_by_todotype',
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 待办列表查询
     */
    static async todoSearch(params) {
        const res = await API.Global.request({
            url: '/api/todo/todo/v1/search',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 保存隐藏字段
     */
    static async saveHideField(params) {
        const res = await API.Global.request({
            url: '/api/todo/field/v1/save',
            method: 'post',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
     * 获取已隐藏字段
     */
    static async getHideField(params) {
        const res = await API.Global.request({
            url: '/api/todo/field/v1/get_hide_field',
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 获取待办应用列表
     */
    static async getAppList(params) {
        const res = await API.Global.request({
            url: '/api/todo/todo/v1/get_allappcode_by_todotype',
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 批量提交任务
     */
    static async batchSubmit(params) {
        const res = await API.Global.request({
            url: `/api/launcher/${params.appCode}/application/flow/work_item/batch_submit`,
            method: 'post',
            data: params.batch,
        });
        return commonErrHandler(res);
    }
    /**
     * 获取流程待办统计
     */
    static async searchCount() {
        const res = await API.Global.request({
            url: '/api/todo/todo/v1/searchCount',
            method: 'get',
        });
        return commonErrHandler(res);
    }
}

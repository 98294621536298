import Vue from 'vue';
import { PermManageService } from '@/sevices';
const Global = {
    namespaced: true,
    state: {
        needOpenBrowser: false,
    },
    mutations: {
        setOpenBrowserStatus(state, flag) {
            state.needOpenBrowser = flag;
        },
    },
    actions: {
        /**
       * 获取所有权限
       */
        async getAllPerms() {
            const res = await PermManageService.getAllPerms();
            if (res.success) {
                try {
                    JSON.parse(JSON.stringify(res.data));
                }
                catch (e) {
                    Vue.prototype.$errorcatcher.showMessagePopup({
                        title: '错误提示！',
                        message: [e, '!'],
                        displayFooter: false,
                        correctGuide: '请检查权限数据JOSN格式是否正确',
                    });
                    throw new Error('请检查权限数据JOSN格式是否正确');
                }
                const data = res.data || {};
                localStorage.setItem('permList', JSON.stringify(data.resourceList));
                localStorage.setItem('appPermList', JSON.stringify(data.appPerm));
                localStorage.setItem('isAllAppPerm', JSON.stringify(data.allAppPerm));
                localStorage.setItem('isSystemManager', data.isSystemManager);
            }
            else {
                throw new Error('获取权限异常');
            }
        },
    },
    getters: {},
};
export default Global;

import API, { commonErrHandler } from './config';
const prefix = '/api/permission';
export class AppAuthService {
    static async getRuntimeApp(params) {
        const res = await API.Global.request({
            url: `/api/launcher/${params.appCode}/app/get`,
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
    static async addAppAuthData(params) {
        const res = await API.Global.request({
            url: '/api/permission/perm/feature/update/app_power_all',
            method: 'put',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAppAuthDetailData(params) {
        const res = await API.Global.request({
            url: '/api/permission/perm/feature/query/all',
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async getApp(params) {
        const res = await API.Global.request({
            url: '/api/metadata/apps/app/getApp',
            method: 'get',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async addAuthGroup(params) {
        const res = await API.Global.request({
            url: prefix + '/perm/group/add',
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async updateAuthGroup(params) {
        const res = await API.Global.request({
            url: prefix + '/perm/group/update',
            method: 'put',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async deleteAuthGroup(params) {
        const res = await API.Global.request({
            url: prefix + '/perm/group/delete',
            method: 'delete',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAuthList(params) {
        const res = await API.Global.request({
            url: prefix + '/perm/group/query/list',
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAuthDetail(params) {
        const res = await API.Global.request({
            url: prefix + '/perm/group/query/id',
            method: 'get',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAppList(params) {
        const res = await API.Global.request({
            url: '/api/operationadmin/app/v1/search',
            method: 'get',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAppDetail(params) {
        const res = await API.Global.request({
            url: '/api/operationadmin/app/v1/get_app',
            method: 'get',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAppAccessType(params) {
        const res = await API.Global.request({
            url: '/api/commonservice/app/v1/app_visit_type',
            method: 'get',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async updateAppAccess(params) {
        const res = await API.Global.request({
            url: '/api/commonservice/app/v1/app_visit_type/update',
            method: 'post',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async updatePublished(params) {
        const res = await API.Global.request({
            url: '/api/operationadmin/app/v1/update_published',
            method: 'get',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async checkModelRule(params) {
        const res = await API.Global.request({
            url: '/api/metadataruntime/rule/get',
            method: 'get',
            params: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAppPages(params) {
        const res = await API.Global.request({
            url: '/api/metadata/app/get',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
}

export default {
    // 系统管理
    sysManage: 'sys-manage',
    // 组织管理
    orgManage: 'sys-manage-org-manage',
    // 安全设置
    safeConfig: 'safe-config',
    // 密码规则
    passwordRule: 'password-rule',
    // 授权管理员
    authManager: 'manager-range',
    // 权限管理
    permManage: 'manager-manage',
    // 数据字典
    dataDictionary: 'sys-data-dictionary',
    // 日志消息
    logMessage: 'log-message',
    // 密码修改日志
    passwordUpdateLog: 'password-update-log',
    // 应用管理日志
    appManageLog: 'app-manage-log',
    // 应用操作日志
    appOperateLog: 'app-operate-log',
    // 组织权限管理日志
    orgPermManageLog: 'org-perm-manage-log',
    // 登录日志
    loginLog: 'login-log',
    // 公共服务扩展
    commonServiceExtension: 'common-service-extension',
};

import appManage from './perm-code-app-manage';
import sysManage from './perm-code-system-manage';
import flowManage from './perm-code-flow-manage';
import organization from './perm-code-organization';

export default {
  appManage,
  sysManage,
  flowManage,
  organization,
};

import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Perm from '@/router/permission';
import PermCode from '@/router/perm-code';
import { IS_DINGTALK, IS_WECHAT, } from '@cloudpivot-hermes/nail-free-login';
const OrganizationModule = namespace('Organization');
var Platform;
(function (Platform) {
    Platform["workPlatform"] = "workPlatform";
    Platform["frontend"] = "frontend";
    Platform["integratedPlatform"] = "integratedPlatform";
})(Platform || (Platform = {}));
let DefaultHeader = class DefaultHeader extends Vue {
    constructor() {
        super(...arguments);
        this.Platform = Platform;
        this.modules = [{
                name: '应用管理',
                code: PermCode.appManage.appManage,
                path: '/app-manage',
            }, {
                name: '流程管理',
                code: PermCode.flowManage.workflowManage,
                path: '/flow-manage',
            }, {
                name: '组织架构',
                code: PermCode.organization.organization,
                path: '/organization',
            }, {
                name: '系统管理',
                code: PermCode.sysManage.sysManage,
                path: '/system-manage',
            }];
    }
    get isThirDintegration() {
        return IS_DINGTALK || IS_WECHAT;
    }
    get userModules() {
        return Perm.filterNavigation(this.modules);
    }
    get isShowMenu() {
        return !!this.orgCount;
    }
    get isAdmin() {
        const res = Perm.isAdmin();
        return res;
    }
    get isSysAdmin() {
        const res = Perm.isSysAdmin();
        return res;
    }
    get platformList() {
        const platformList = [
            {
                name: '工作台',
                type: Platform.workPlatform,
            },
        ];
        if (this.isAdmin) {
            platformList.push({
                name: '运营管理平台',
                type: Platform.frontend,
            });
        }
        if (this.isSysAdmin) {
            platformList.push({
                name: '集成开发平台',
                type: Platform.integratedPlatform,
            });
        }
        return platformList;
    }
    toggle(type) {
        if (this.sourcePageType === type)
            return;
        if (type === Platform.frontend) {
            this.goOperator();
        }
        else if (type === Platform.workPlatform) {
            this.$router.push({
                name: 'work-platform-home',
            });
        }
        else {
            this.goOperator('integrate');
        }
    }
    /**
     * 跳转不同平台
     */
    goPage() {
        const origin = window.location.origin;
        const url = origin + '/app_integrate/';
        window.open(url, '_blank');
    }
    async goOperator(type) {
        if (type === 'integrate') {
            this.goPage();
            return;
        }
        const origin = window.location.origin + '/public/#';
        let url = origin + '/app-manage';
        const isSysAdmin = Perm.isSysAdmin();
        const sysManage = Perm.hasCodePerm(PermCode.sysManage.sysManage);
        const organization = Perm.hasCodePerm(PermCode.organization.organization);
        const workflowManage = Perm.hasCodePerm(PermCode.flowManage.workflowManage);
        const appManage = Perm.hasCodePerm(PermCode.appManage.appManage);
        if (!isSysAdmin) {
            if (sysManage) {
                url = origin + '/system-manage';
            }
            if (organization) {
                url = origin + '/organization';
            }
            if (workflowManage) {
                url = origin + '/flow-manage';
            }
            if (appManage) {
                url = origin + '/app-manage';
            }
        }
        if (this.isThirDintegration) {
            url = `${url}?token=${window.localStorage.getItem('token')}&userInfo=userInfo`;
        }
        this.checkOrg().then(() => {
            window.open(url, '_blank');
        });
    }
    created() {
        window.document.title = this.sourcePageType === Platform.frontend ? '云枢-运营管理后台' : '云枢-工作台';
    }
};
__decorate([
    OrganizationModule.State('orgCount')
], DefaultHeader.prototype, "orgCount", void 0);
__decorate([
    OrganizationModule.Action('checkOrg')
], DefaultHeader.prototype, "checkOrg", void 0);
__decorate([
    Prop()
], DefaultHeader.prototype, "sourcePageType", void 0);
DefaultHeader = __decorate([
    Component({
        name: 'DefaultHeader',
        components: {
            Setting: () => import('../setting/index.vue'),
        },
    })
], DefaultHeader);
export default DefaultHeader;

import { Vue } from 'vue-property-decorator';
const noxss = (text) => {
    const textStr = text || '';
    return textStr
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
};
Vue.directive('hight-light', {
    bind(el, binding) {
        let targetStr = binding.value.value;
        if (binding.value.keyWords && binding.value.keyWords.length > 0) {
            const replaceReg = new RegExp(binding.value.keyWords, 'g');
            const replaceString = '<span class="highlight">' + noxss(binding.value.keyWords) + '</span>';
            targetStr = targetStr?.replace(replaceReg, replaceString);
        }
        else {
            targetStr = noxss(targetStr);
        }
        el.innerHTML = `${targetStr}`;
    },
    update(el, binding) {
        let targetStr = binding.value.value;
        if (binding.value.keyWords && binding.value.keyWords.length > 0) {
            const replaceReg = new RegExp(binding.value.keyWords, 'g');
            const replaceString = '<span class="highlight">' + noxss(binding.value.keyWords) + '</span>';
            targetStr = targetStr?.replace(replaceReg, replaceString);
        }
        else {
            targetStr = noxss(targetStr);
        }
        el.innerHTML = `${targetStr}`;
    },
});

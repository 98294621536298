import API, { commonErrHandler } from './config';
const LoginApi = API.Authorization.Login;
export class LoginService {
    static async getKey() {
        const res = await LoginApi.getRsaKey();
        return commonErrHandler(res, true);
    }
    static async login(params) {
        const res = await API.Global.request({
            url: '/api/authorization/login/password/user',
            method: 'post',
            data: params,
        });
        return commonErrHandler(res, true);
    }
    static async getAllPerms() {
        const res = await API.Global.request({
            url: '/api/permission/customs/perms',
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
    /**
     * 应用开发平台
     */
    static async manageLogin(params) {
        const res = await API.Global.request({
            url: '/api/authorization/login/password/manager',
            method: 'post',
            data: params,
        });
        ;
        return commonErrHandler(res, true);
    }
    static async getToken(params) {
        const res = await LoginApi.getAccessToken(params);
        return commonErrHandler(res);
    }
    static async logout(params) {
        const res = await API.Global.request({
            url: '/api/authorization/logout',
            method: 'post',
            data: params,
        });
        return res;
    }
}

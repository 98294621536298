/** 组织人员 */
const organization = {
    organization: () => import('@/views/organization/index.vue'),
    organizationManage: () => import('@/views/organization/org-manage/index.vue'),
    labels: () => import('@/views/organization/label-manage/labels.vue'),
    specialUseLabel: () => import('@/views/organization/label-manage/special-use-label.vue'),
    exServingOfficer: () => import('@/views/organization/staff-manage/ex-serving.vue'),
    staffManage: () => import('@/views/organization/staff-manage/in-service.vue'),
};
/** 应用管理 */
const appManage = {
    appManage: () => import('@/views/app-manage/index.vue'),
    appInstalled: () => import('@/views/app-manage/app-installed/index.vue'),
    appDetail: () => import('@/views/app-manage/app-detail/index.vue'),
    authConfig: () => import('@/views/app-manage/auth-config/index.vue'),
    authConfigSet: () => import('@/views/app-manage/auth-config-set/index.vue'),
    modelConfig: () => import('@/views/app-manage/model-config/index.vue'),
};
/** 流程管理 */
const flowManage = {
    flowManage: () => import('@/views/flow-manage/index.vue'),
    flowInstance: () => import('@/views/flow-manage/flow-instance/index.vue'),
    flowEntrust: () => import('@/views/flow-manage/flow-entrust/index.vue'),
    flowException: () => import('@/views/flow-manage/flow-exception/index.vue'),
};
/** 系统管理 */
const systemManage = {
    systemManage: () => import('@/views/system-manage/index.vue'),
    orgManage: () => import('@/views/system-manage/org-manage/index.vue'),
    orgManageDetail: () => import('@/views/system-manage/org-manage/detail.vue'),
    sysDataDictionary: () => import('@/views/system-manage/data-dictionary/index.vue'),
    passwordRule: () => import('@/views/system-manage/security-setting/password-rule/index.vue'),
    appManageLog: () => import('@/views/system-manage/log-manage/app-manage-log.vue'),
    appOperateLog: () => import('@/views/system-manage/log-manage/app-operate-log.vue'),
    orgPermissionLog: () => import('@/views/system-manage/log-manage/org-permission-log.vue'),
    loginLog: () => import('@/views/system-manage/log-manage/login-log.vue'),
    passwordUpdateLog: () => import('@/views/system-manage/log-manage/password-log.vue'),
    commonServiceExtension: () => import('@/views/system-manage/service-extension/common-service-extension.vue'),
    permManage: () => import('@/views/system-manage/perm-manage/index.vue'),
    authManager: () => import('@/views/system-manage/perm-manage/auth-manager.vue'),
};
export const ComponentMap = {
    organization,
    appManage,
    flowManage,
    systemManage,
};
export default ComponentMap;

import ComponentMap from '../component-map';
import PermCode from '../perm-code';
import Perm from '@/router/permission';
const Organization = ComponentMap.organization;
const PermCodeModule = PermCode.organization;
export default () => {
    return [
        {
            path: '/organization',
            meta: {
                title: '云枢-组织架构',
                pagePermCode: PermCodeModule.organization,
            },
            component: Organization.organization,
            children: Perm.addRedirectToChildRoute([
                {
                    path: '/',
                    redirect: {
                        name: 'in-service-staff',
                    },
                },
                {
                    path: 'in-service-staff',
                    name: 'in-service-staff',
                    meta: {
                        title: '云枢-平台账号',
                        pagePermCode: PermCodeModule.inServiceStaff,
                        code: PermCodeModule.inServiceStaff,
                    },
                    component: Organization.staffManage,
                },
                {
                    path: 'organization-manage',
                    name: 'organization-manage',
                    meta: {
                        title: '云枢-组织架构管理',
                        pagePermCode: PermCodeModule.organizationManage,
                        code: PermCodeModule.organizationManage,
                    },
                    component: Organization.organizationManage,
                },
                {
                    path: 'ex-serving-officer/:orgId',
                    name: 'ex-serving-officer',
                    meta: {
                        title: '云枢-离职人员',
                        pagePermCode: PermCodeModule.orgDeptManage,
                        code: PermCodeModule.exServingOfficer,
                    },
                    component: Organization.exServingOfficer,
                },
                {
                    path: 'labels',
                    name: 'labels',
                    meta: {
                        title: '云枢-通用标签',
                        pagePermCode: PermCodeModule.labels,
                        code: PermCodeModule.labels,
                    },
                    component: Organization.labels,
                },
                {
                    path: 'special-use-label',
                    name: 'special-use-label',
                    meta: {
                        title: '云枢-组织专用标签',
                        pagePermCode: PermCodeModule.specialUseLabel,
                        code: PermCodeModule.specialUseLabel,
                    },
                    component: Organization.specialUseLabel,
                },
            ]),
        },
    ];
};

import API, { commonErrHandler } from './config';
export class WorkPlatformService {
    /**
     * 获取待办列表
     */
    static async getTodoList(params) {
        const res = await API.Global.request({
            url: '/api/todo/todo/v1/search',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取应用列表
     */
    static async getAppList(params) {
        // const res: any = await API.Todo.App.search(params);
        const res = await API.Global.request({
            url: '/api/todo/app/deploy/v1/search',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取流程|应用链接
     */
    static async getAppUrl(params) {
        // const res: any = await API.Todo.App.getAppUrl(params);
        const res = await API.Global.request({
            url: '/api/todo/app/deploy/v1/get_app_url',
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取组织列表
     */
    static async getOrgList() {
        const res = await API.Global.request({
            url: '/api/org/user/list_user_multiorg',
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
    /**
     * 设置组织
     */
    static async setOrg(data) {
        const res = await API.Global.request({
            url: `/api/org/user/choose_multiorg?corpId=${data}`,
            method: 'post',
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取待办数量
     */
    static async getWorkItemCount() {
        const res = await API.Global.request({
            url: '/api/commonservice/todo/v1/count_workitem',
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
}

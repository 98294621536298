import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DefaultHeader from './defaultHeader.vue';
import BackHeader from './backHeader.vue';
let HeaderBar = class HeaderBar extends Vue {
    get currentHeader() {
        const routeName = this.$route.name;
        let header = DefaultHeader;
        switch (routeName) {
            case 'auth-config-set':
            case 'flow-center':
                header = BackHeader;
                break;
            default:
                header = DefaultHeader;
                break;
        }
        return header;
    }
    mounted() { }
};
HeaderBar = __decorate([
    Component({
        name: 'HeaderBar',
        components: {
            DefaultHeader,
        },
    })
], HeaderBar);
export default HeaderBar;

import API, { commonErrHandler } from './config';
const prefix = '/api/commonservice';
export class ModifyPasswordService {
    /**
     * 校验原密码
     */
    static async checkOldPassword(params, baseUserId) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/${baseUserId}/password/check`,
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 修改密码
     */
    static async modifyPassword(params, baseUserId, ruleToken) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/${baseUserId}/password/modify`,
            method: 'put',
            params,
            headers: {
                ruleToken: ruleToken,
            },
        });
        return commonErrHandler(res);
    }
    static async getPasswordRule() {
        const res = await API.Global.request({
            url: `${prefix}/security/password_rule`,
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取密码规则（正则，提示）
     */
    static async getPasswordRuleInfo(ruleToken) {
        const res = await API.Global.request({
            url: `${prefix}/security/password_regular_expression`,
            method: 'get',
            headers: {
                ruleToken: ruleToken,
            },
        });
        return commonErrHandler(res);
    }
    static async setPasswordRule(params) {
        const res = await API.Global.request({
            url: `${prefix}/security/password_rule`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res, true);
    }
}

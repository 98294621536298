import ComponentMap from '../component-map';
import PermCode from '../perm-code';
const AppManage = ComponentMap.appManage;
const PermCodeModule = PermCode.appManage;
export default () => {
    return [
        {
            path: '/app-manage',
            name: 'app-manage',
            component: AppManage.appManage,
            meta: {
                pagePermCode: PermCodeModule.appManage,
            },
            children: [
                {
                    path: '/',
                    redirect: {
                        name: 'app-installed',
                    },
                },
                {
                    path: 'app-installed',
                    name: 'app-installed',
                    meta: {
                        title: '云枢-已安装应用',
                        pagePermCode: PermCodeModule.appManage,
                    },
                    component: AppManage.appInstalled,
                },
                {
                    path: 'app-detail/:appCode/:appVersion',
                    name: 'app-detail',
                    meta: {
                        title: '云枢-应用详情',
                        pagePermCode: PermCodeModule.appManage,
                    },
                    component: AppManage.appDetail,
                },
                {
                    path: 'auth-config/:appCode/:appVersion',
                    name: 'auth-config',
                    meta: {
                        title: '云枢-权限配置',
                        pagePermCode: PermCodeModule.appManage,
                    },
                    component: AppManage.authConfig,
                },
                {
                    path: 'auth-config-set/:appCode/:appVersion',
                    name: 'auth-config-set',
                    meta: {
                        title: '云枢-权限设置',
                        pagePermCode: PermCodeModule.appManage,
                    },
                    component: AppManage.authConfigSet,
                },
                {
                    path: 'model-config/:appCode/:appVersion',
                    name: 'model-config',
                    meta: {
                        title: '云枢-模型必填模型配置',
                        pagePermCode: PermCodeModule.appManage,
                    },
                    component: AppManage.modelConfig,
                },
            ],
        },
    ];
};

import Vue from 'vue';
import Router from 'vue-router';
import Perm from '@/router/permission';
import workPlatform from './router-list/workPlatform';
import mWorkPlatform from './router-list/mWorkPlatform';
import organization from './router-list/organization';
import systemManage from './router-list/system';
import appManage from './router-list/app';
import flowManage from './router-list/flow-manage';
import { isPC, isPhone } from '@/common/utils';
Vue.use(Router);
/**
 * 创建路由的工厂函数
 */
const createRouter = () => {
    return new Router({
        routes: [
            ...workPlatform,
            ...mWorkPlatform,
            ...organization(),
            ...systemManage(),
            ...appManage(),
            ...flowManage(),
            {
                path: '/login/:type',
                name: 'login',
                component: () => import('@/views/login/index.vue'),
                meta: {
                    noShowHeader: true,
                },
            },
            {
                path: '/no-permission',
                name: 'NoPermission',
                component: () => import('@/views/no-permission.vue'),
            },
            {
                path: '/page404',
                name: 'page404',
                component: () => import('@/views/page-404.vue'),
            },
            {
                path: '/perm-manage',
                name: 'perm-manage',
                component: () => import('@/views/system-manage/perm-manage'),
            },
            {
                path: '/auth-manager',
                name: 'auth-manager',
                component: () => import('@/views/system-manage/perm-manage/auth-manager.vue'),
            },
            {
                path: '/form-test',
                name: 'form-test',
                component: () => import('@/views/c-form-test.vue'),
            },
            {
                path: '*',
                redirect: { name: 'work-platform-home' },
            },
        ],
    });
};
// 初始化路由
const router = createRouter();
const routes = router.options.routes;
console.log(routes);
/**
 * 重置addRoutes的路由
 */
export const resetRouter = () => {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
    const newroutes = router.options.routes;
    console.log(newroutes);
};
// 判断页面是否为404
const isPage404Fun = (to) => {
    let isEmpty = true;
    for (let i = 0, len = routes.length; i < len; i++) {
        const item = routes[i];
        if (item.name === to.name) {
            isEmpty = false;
            break;
        }
        if (item.children?.length) {
            for (let c = 0, clen = item.children.length; c < clen; c++) {
                const child = item.children[c] || {};
                if (child.name === to.name) {
                    isEmpty = false;
                    break;
                }
                else {
                    isEmpty = true;
                }
            }
        }
        else {
            isEmpty = true;
        }
        if (!isEmpty) {
            break;
        }
    }
    return isEmpty;
};
// 守卫导航抛出错误时的处理回调函数
router.onError((error) => {
    const pattern = /Loading chunk [a-zA-Z0-9_-]+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if (isChunkLoadFailed) {
        Vue.prototype.$errorcatcher.showMessagePopup({
            title: '错误提示！',
            message: ['Loading chunk xxx failed', '路由懒加载找不到对应的moudle'],
            correctGuide: '系统升级更新，请通过F5刷新页面获取最新资源',
            autoHide: true,
            onClose: () => window.location.reload(),
        });
    }
    else {
        // console.log(error);
    }
});
const noPermissionPage = [
    'm-work-platform-login',
    'm-work-platform-change-password',
    'login',
    'NoPermission',
];
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    // console.log('isPc', isPC());
    // console.log('isPhone', isPhone());
    if (!token) {
        // 没有登录的处理
        if (isPhone() && to.name !== 'm-work-platform-login' && to.name !== 'm-work-platform-change-password') {
            // console.log('跳转至手机登录');
            next('/m-work-platform-login');
        }
        else if (isPC() && to.name !== 'login') {
            // console.log('跳转至PC登录');
            const loginUrl = `/login/${to.meta.isWorkPlatform ? 'user' : 'operation'}`;
            let redirectUri = window.location.href;
            redirectUri = redirectUri.replace('#', 'hashsymbol');
            next({ path: loginUrl, query: { redirect_url: redirectUri } });
        }
        else {
            next();
        }
        return;
    }
    const isPage404 = isPage404Fun(to);
    if (isPage404) {
        next('/page404');
        return;
    }
    if (isPhone() && to.name === 'work-platform-home') {
        next('/m-work-platform');
    }
    else if (isPC() && to.name === 'm-work-platform-home') {
        next('/work-platform-home');
    }
    if (noPermissionPage.includes(to.name)) {
        // 不需要验证权限的页面
        // 如果是登录页面
        if (to.name === 'm-work-platform-login') {
            next('/m-work-platform');
        }
        else if (to.name === 'login') {
            next('/');
        }
        else {
            next(); // 无权限页面
        }
    }
    else {
        // 超管和系管不需要验证权限，拥有所有权限
        const isSysAdmin = Perm.isSysAdmin();
        if (isSysAdmin) {
            next();
            return;
        }
        // 需要验证权限
        const isWorkPlatform = to.meta.isWorkPlatform;
        const pagePermCode = to.meta && to.meta.pagePermCode;
        const hasCodePerm = Perm.hasCodePerm(pagePermCode);
        if (!isWorkPlatform && !hasCodePerm) {
            next('/no-permission');
            return;
        }
        next();
    }
});
export default router;

import API from '@cloudpivot-shared/domain-api';
import router from '@/router';
import { removeStorage } from '@/common/utils';
import store from '@/store';
// const api = {
//   config: '/api/sm4/security/get/switch/config', // 查看服务是否开启加密
//   sm2: '/api/sm4/security/generate/sm2/public_key', // 获取sm2PublicKey服务端地址
//   sm4: '/api/sm4/security/record/sm4/secret', // 同步加密sm4key的服务端地址
// };
// /*
//  * 设置加密配置
//  */
// export default async function setupEncrypt () {
//   // 1.1 先关闭加密功能，
//   API.Encrypt.setConfig({ enabled: false });
//   // 1.2 检查服务端是否开启加密功能
//   const resConfig = await API.Global.request({
//     url: api.config,
//   });
//   // 1.3 如果没开启，退出
//   if (resConfig.data?.globalSwitch === 'close') {
//     return;
//   }
//   // 2.1 创建sessionId
//   const sid = API.Encrypt.createSessionId();
//   // 2.2 获取sm2PublicKey
//   const res = await API.Global.request({
//     url: api.sm2,
//     headers: {
//       sid,
//     },
//     method: 'POST',
//   });
//   // 2.2设置sm2PublicKey
//   API.Encrypt.setConfig({ sm2PublicKey: res.data.sm2PublicKey });
//   // 3.1 生成sm4Key
//   const sm4Key = API.Encrypt.createSM4Key();
//   // 3.2 用sm2PublicKey加密sm4Key
//   const encryptedSm4Key = API.Encrypt.encrypt(sm4Key, 'sm2');
//   // 3.3 将加密的sm4Key发送给服务端同步
//   await API.Global.request({
//     url: api.sm4,
//     headers: {
//       sid,
//     },
//     method: 'POST',
//     data: {
//       encryptedSm4Key,
//       sid,
//     },
//   });
//   // 同步完成，开启sm4加密
//   API.Encrypt.setConfig({
//     enabled: true,
//     type: 'sm4',
//   });
// }
// /**
//  * 添加全局请求密钥过期拦截器
//  */
// API.Global.addInterceptor(
//   'response',
//   async res => {
//     const getStatus = (response: any) => {
//       return (
//         response.status || (response.orgResponse && response.orgResponse.status)
//       );
//     };
//     // 响应状态203为密钥失效
//     if (getStatus(res) === 203) {
//       // 还原加密
//       const orgRequestConfig = API.Encrypt.decryptRequest(
//         res.orgResponse.config,
//       );
//       // 重设加密条件
//       await setupEncrypt();
//       // 重新发起请求
//       return await API.Global.request(orgRequestConfig);
//     }
//   },
//   true,
//   'renewEncryptConfig',
// );
API.Global.addInterceptor('responseError', err => {
    const getErrorStatus = (error) => error.httpStatus || (error.response && error.response.status);
    const status = getErrorStatus(err);
    if (status === 401) {
        removeStorage(store);
        let url = '/public/#/login';
        const href = window.location.href;
        const currentRoute = router.currentRoute;
        const isLogin = href.includes('/login');
        if (!isLogin) {
            const isWorkPlatform = currentRoute.meta.isWorkPlatform;
            if (isWorkPlatform) {
                url += '/user';
            }
            else {
                url += '/operation';
            }
            let redirectUri = window.location.href;
            redirectUri = redirectUri.replace('#', 'hashsymbol');
            window.location.href = `${url}?redirect_url=${redirectUri}`;
        }
        return true;
    }
    else if (status === 403) {
        return true;
    }
    return false;
}, true);
// API的请求接口拦截器
API.Global.addInterceptor('request', config => {
    // 忽略添加token的地址
    const ignoredTokenURLs = [
        '/api/authorization/login/auth/sign',
        '/api/authorization/login/ding_talk',
        '/api/sm4/security/get/switch/config',
        '/api/sm4/security/generate/sm2/public_key',
        '/api/sm4/security/record/sm4/secret',
        '/api/authorization/login/auth/sign',
        '/api/authorization/login/wx_login',
        '/api/authorization/login/auth/get_rsa_key',
    ];
    const hasIgnored = ignoredTokenURLs.some(i => {
        return config.url.indexOf(i) !== -1;
    });
    if (hasIgnored) {
        if (config.headers.Authorization) {
            delete config.headers.Authorization;
        }
        return true;
    }
    // 其他地址查找localStorag 的token,自动附带到请求头上
    let token = localStorage.getItem('token');
    if (!token && config.headers.ruleToken) {
        token = config.headers.ruleToken;
        delete config.headers.ruleToken;
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return true;
}, false);

import API, { commonErrHandler } from './config';
const prefix = '/api/commonservice';
/**
 *  组织配置
 */
export class OrgManageService {
    /** ****************** 组织接口 *********************/
    /**
     * 选人控件树（调试）
    */
    static async treeApiMethod(params, options) {
        const res = await API.Global.request({
            url: '/api/commonservice/organization/department/tree',
            method: 'get',
            params,
            ...options,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 选人控件搜索（调试）
    */
    static async searchApiMethod(params, options) {
        const res = await API.Global.request({
            url: '/api/commonservice/organization/department/tree/search',
            method: 'get',
            params,
            ...options,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 获取所有子组织
    */
    static async getSubOrganization(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/sub_organization`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 查询组织配置列表
    */
    static async searchOrgConfigList(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/search`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 查询组织列表（下拉列表）
    */
    static async getSelectOrgList(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/list`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 注册增量同步
    */
    static async registerCallbackUrl(params) {
        const res = await API.Global.request({
            url: `${prefix}/public/dingtalk/register_callback_url`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 组织同步
    */
    static async orgSync(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/synchronize/full`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 校验是否存在组织
    */
    static async getOrgCheck() {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/check`,
            method: 'get',
        });
        return commonErrHandler(res);
    }
    /**
     * 添加组织
    */
    static async addOrg(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
     * 更新组织
    */
    static async updateOrg(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
     * 获取组织详情
    */
    static async getOrgDetail(id) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/${id}`,
            method: 'get',
        });
        return commonErrHandler(res);
    }
    /**
     * 获取工作台的组织列表
    */
    static async getOrgList() {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/list_by_baseuserid`,
            method: 'get',
        });
        return commonErrHandler(res);
    }
    /**
     * 获取工作台切换组织
    */
    static async setOrg(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/choose_organization`,
            method: 'post',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 删除组织
    */
    static async deleteOrg(id) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/${id}`,
            method: 'delete',
        });
        return commonErrHandler(res);
    }
    /**
     * 停用启用组织
    */
    static async enableOrg(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/config/${params.id}/status`,
            method: 'put',
            params: {
                status: params.status,
                lowerLevel: params.lowerLevel,
            },
        });
        return commonErrHandler(res);
    }
    /** **************************** 部门接口 ********************************/
    /**
     * 根据上级部门获取其下级部门
     */
    static async childrens(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/list_by_parent_id`,
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 根据id获取部门
     */
    static async getDeptDetail(params, options = {}) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/${params.id}`,
            method: 'get',
            ...options,
        });
        return commonErrHandler(res);
    }
    /**
     * 根据部门id获取上级所有部门信息
     */
    static async getAllParentTree(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/get_all_parent_tree`,
            method: 'get',
            params,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 根据搜索关键词搜索部门
     */
    static async search(params, option) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/search`,
            method: 'get',
            params,
            ...option,
        });
        return commonErrHandler(res, true);
    }
    /**
     * 添加子部门
     */
    static async addDept(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
       * 修改部门
       */
    static async updateDept(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
     * 删除部门
     */
    static async deleteDepartment(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/${params.id}`,
            method: 'delete',
        });
        return commonErrHandler(res);
    }
    /**
     * 设置主管
     */
    static async setManager(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/set_department_manager`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
     * 调整人员部门
     */
    static async batchUpdateDept(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/adjust_department_user`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
     * 从其它部门复制用户
     */
    static async copyUserFromDept(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/copy`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
     * 根据ids获取所有部门节点
     */
    static async getDepts(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/list_by_ids`,
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /** ******************* 用户接口 ******************** */
    /**
     * 获取在职离职人员列表
     */
    static async getEnableList(params, options = {}) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/search_user`,
            method: 'get',
            params,
            ...options,
        });
        return commonErrHandler(res, true);
    }
    /**
   * 添加人员
   */
    static async createUser(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user`,
            method: 'post',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
   * 修改人员
   */
    static async updateUser(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
   * 重置人员密码
   */
    static async resetUserPass(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/${params.baseUserId}/password/reset`,
            method: 'put',
        });
        return commonErrHandler(res);
    }
    /**
   * 置顶人员
   */
    static async userToTop(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/top`,
            method: 'put',
            data: params,
        });
        return commonErrHandler(res);
    }
    /**
   * 批量删除人员
   */
    static async batchDeleteUser(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/users`,
            method: 'delete',
            params,
        });
        return commonErrHandler(res);
    }
    /**
   * 删除人员
   */
    static async deleteUser(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/${params.deptId}/${params.id}`,
            method: 'delete',
        });
        return commonErrHandler(res);
    }
    /**
   * 获取用户信息
   */
    static async getLoginInfo() {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/get_login_info`,
            method: 'get',
        });
        return commonErrHandler(res);
    }
    /**
   * 获取在职人员列表
   */
    static async getUserList(params, options) {
        const res = await API.Global.request({
            url: `${prefix}/organization/department/user`,
            method: 'get',
            params,
            ...options,
        });
        return commonErrHandler(res, true);
    }
    /**
   *根据id查询用户
   */
    static async getUserDetail(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/${params.id}`,
            method: 'get',
        });
        return commonErrHandler(res);
    }
    /**
   *查询多组织用户
   */
    static async getUserDetailWidthMultiOrg(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/users_by_base_user_id`,
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 根据ids获取所有用户节点
     */
    static async getUsers(params) {
        const res = await API.Global.request({
            url: `${prefix}/organization/user/list_by_ids`,
            method: 'get',
            params,
        });
        return commonErrHandler(res);
    }
    /**
     * 导出人员
     */
    static async exportUser(params, onDownloadProgress) {
        return API.Global.request({
            url: `${prefix}/organization/user/export`,
            method: 'post',
            responseType: 'blob',
            data: params,
            onDownloadProgress,
        });
    }
    /**
     * 导出部门
     */
    static async exportDept(params, onDownloadProgress) {
        return API.Global.request({
            url: `${prefix}/organization/department/export`,
            method: 'post',
            responseType: 'blob',
            params: params,
            onDownloadProgress,
        });
    }
    /**
     * 导入用户
     * @param appCode 应用code
     * @param uploadFile 上传的jar包
     */
    static async uploadUser(uploadFile, params, progressClb) {
        const file = new FormData();
        file.append('file', uploadFile);
        Object.keys(params).forEach((key) => {
            file.append(key, params[key]);
        });
        // console.log('file', file);
        return API.Global.request({
            url: `${prefix}/organization/user/import/excel`,
            method: 'post',
            data: file,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressClb,
        });
    }
    /**
     * 下载用户模板
     */
    static async downloadUserTemplate(onDownloadProgress) {
        return API.Global.request({
            url: `${prefix}/organization/user/template`,
            method: 'get',
            responseType: 'blob',
            headers: { 'Content-Type': 'multipart/x-www-form-urlencoded' },
            onDownloadProgress,
        });
    }
    /**
     * 导入部门
     * @param appCode 应用code
     * @param uploadFile 上传的jar包
     */
    static async uploadDept(uploadFile, params, progressClb) {
        const file = new FormData();
        file.append('file', uploadFile);
        Object.keys(params).forEach((key) => {
            file.append(key, params[key]);
        });
        // console.log('file', file);
        return API.Global.request({
            url: `${prefix}/organization/department/import/excel`,
            method: 'post',
            data: file,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressClb,
        });
    }
    /**
     * 下载部门模板
     */
    static async downloadDeptTemplate(onDownloadProgress) {
        return API.Global.request({
            url: `${prefix}/organization/department/template`,
            method: 'get',
            responseType: 'blob',
            headers: { 'Content-Type': 'multipart/x-www-form-urlencoded' },
            onDownloadProgress,
        });
    }
    /**
     * 下载文件
     */
    static async downloadTemplate(params, onDownloadProgress) {
        return API.Global.request({
            url: `${prefix}/download`,
            method: 'get',
            responseType: 'blob',
            headers: { 'Content-Type': 'multipart/x-www-form-urlencoded' },
            onDownloadProgress,
            params,
        });
    }
}

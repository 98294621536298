interface LocalMess {
  acrossServeTargetUrl: string;
  localStorage: string[];
  sessionStorage: string[];
}

interface StorageMess {
  isStorage: boolean;
  localStorage: any;
  sessionStorage: any;
}

interface LocalStorageIframes {
  [index: string]: HTMLIFrameElement;
}

export default class AcrossServeTransferData {
  localstorageIframes: LocalStorageIframes = {};

  localStorage: string[] = [];

  sessionStorage: string[] = [];

  acrossServeTargetUrl: string = '';

  /**
   * 设置目标服务接收数据
   * @returns
   */
  static setTargetReceiveData (): void {
    if (process.env.NODE_ENV === 'production') return;

    window.addEventListener('message', (event: any) => {
      try {
        if (
          event.data === undefined ||
          event.data === null ||
          event.data === 'undefined' ||
          event.data === 'null'
        ) { return; }

        if (event.data.type) return;

        if (typeof event.data !== 'string') return;

        if (!event.data.includes('{') || !event.data.includes('}')) return;

        const data: StorageMess = JSON.parse(event.data);

        if (!data.isStorage) return;

        delete data.isStorage;
        const localStorageData: any = data.localStorage;
        const sessionStorageData: any = data.sessionStorage;

        for (const key in localStorageData) {
          localStorage.setItem(key, localStorageData[key]);
        }

        for (const key in sessionStorageData) {
          sessionStorage.setItem(key, sessionStorageData[key]);
        }

        event.source.postMessage('success', event.origin);
      } catch (err) {
        console.error(err);
      }
    });
  }

  private createIframe (acrossServeTargetUrl: string): HTMLIFrameElement {
    const iframe: HTMLIFrameElement = document.createElement('iframe');

    iframe.setAttribute('data-id', 'localstorageIframe');

    iframe.src = acrossServeTargetUrl;
    iframe.style.display = 'none';
    document.body.append(iframe);

    return iframe;
  }

  private getLocalstorage (): StorageMess {
    const localStorage: any = window.localStorage;
    const sessionStorage: any = window.sessionStorage;

    const data: StorageMess = {
      isStorage: true,
      localStorage: {},
      sessionStorage: {},
    };

    if (this.localStorage.length) {
      for (const key of this.localStorage) {
        data.localStorage[key] = localStorage[key];
      }
    } else {
      data.localStorage = localStorage;
    }

    if (this.sessionStorage.length) {
      for (const key of this.sessionStorage) {
        data.sessionStorage[key] = sessionStorage[key];
      }
    } else {
      data.sessionStorage = sessionStorage;
    }

    return data;
  }

  private sendData (url?: string): Promise<void> {
    return new Promise((resolve) => {
      const acrossServeTargetUrl: string = url || this.acrossServeTargetUrl;

      const reg: RegExp =
      /^(http:\/\/localhost(:\d+)?){1}(\/\w*|\/#?)*((\?|&){1}([a-zA-Z]+=[a-zA-Z0-9\u4e00-\u9fa5_]+))*$/;
      if (!reg.test(acrossServeTargetUrl)) {
        console.error(
          '本环境的用户信息只能复制到本地环境，参数：比如http://localhost或http://localhost:xx等格式',
        );
        return;
      }

      const regArr: string[] = reg.exec(acrossServeTargetUrl);
      const domain: string = Array.isArray(regArr) && regArr[1];

      console.log(`本环境的用户信息正在复制到${domain}中，请稍等...`);

      const data: StorageMess = this.getLocalstorage();
      let iframe: HTMLIFrameElement;

      const existIframe: HTMLIFrameElement = this.localstorageIframes[acrossServeTargetUrl];

      if (!existIframe) {
        iframe = this.createIframe(acrossServeTargetUrl);
        this.localstorageIframes[acrossServeTargetUrl] = iframe;

        iframe.onload = async () => {
          const iframeWin: Window = iframe.contentWindow;
          iframeWin.postMessage(JSON.stringify(data), domain);
          await this.sleep(500);
          resolve();
        };
      } else {
        const execute = async () => {
          iframe = existIframe;
          const iframeWin: Window = iframe.contentWindow;
          iframeWin.postMessage(JSON.stringify(data), domain);
          await this.sleep(500);
          resolve();
        };

        execute();
      }
    });
  }

  private sleep (timer: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, timer);
    });
  }

  init (
    localMess: LocalMess = {
      acrossServeTargetUrl: undefined,
      localStorage: undefined,
      sessionStorage: undefined,
    }): void {
    const {
      acrossServeTargetUrl = 'http://localhost/app_runtime/#/home?iframe=1',
      localStorage = [],
      sessionStorage = [],
    } = localMess;

    this.acrossServeTargetUrl = acrossServeTargetUrl;
    this.localStorage = localStorage;
    this.sessionStorage = sessionStorage;

    window.addEventListener(
      'message',
      function (event: any) {
        if (event.data === 'success') {
          console.log('复制成功');
        }
      },
      false,
    );
  }

  constructor () {
    (window as any).sendData = this.sendData.bind(this);
  }

  static create (): AcrossServeTransferData {
    return new AcrossServeTransferData();
  }
}

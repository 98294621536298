import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import PermCode from '@/router/perm-code';
import Filters from '@/common/filters';
// import { i18n } from "@/config/i18n";
import './styles/index.less';
import './config/index';
import './directives/hight-light';
import './directives/ellipsis';
import AcrossServeTransferData from '@cloudpivot-hermes/across-serve-transfer-data';
import { message, Modal } from '@h3/antd-vue';
// import { IconFont } from '@cloudpivot-shared/property-editor';
// import { H3Toast, H3Modal } from '@h3/thinking-ui';
// const VConsole = require('vconsole');
// // eslint-disable-next-line no-new
// new VConsole();
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$PermCode = PermCode;
Vue.config.productionTip = false;
// 跨服务工程传输缓存数据
AcrossServeTransferData.create().init({
    acrossServeTargetUrl: process.env.VUE_APP_ACROSS_SERVE_TARGET_URL,
    localStorage: ['corpInfo', 'publicUserInfo', 'token', 'user'],
    sessionStorage: ['IS_WECHAT', 'IS_DINGTALK'],
});
// 二次开发时，可以自行引入自定义的图标库,并全局挂载
// const baseUrl = process.env.BASE_URL;
// const iconfontFile = 'iconfont.min.js';
// IconFont.loadingIconFont(
//   Vue,
//   baseUrl ? baseUrl + '/' + iconfontFile : iconfontFile,
// );
// Vue.use(H3Toast);
// Vue.use(H3Modal);
export function formatter(data) {
    let obj = {
        appCode: '',
        groupCode: '',
        pageGroupCode: '',
        pageCode: '',
        pageType: '',
    };
    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (data.pageJson && typeof data.pageJson === 'string') {
        const pageJson = JSON.parse(data.pageJson);
        if (!Array.isArray(pageJson))
            return;
        if (isMobile) {
            const mobilePage = pageJson.find(p => p.pageType === 'MOBILE');
            if (mobilePage && mobilePage.pageCode) {
                obj = { ...obj, ...mobilePage };
            }
            else {
                Vue.prototype.$message.error('当前未绑定移动端页面，请检查设置！');
            }
        }
        else {
            const allPage = pageJson.find(p => p.pageType === 'ALL');
            const pcPage = pageJson.find(p => p.pageType === 'PC');
            if (allPage && allPage.pageCode) {
                obj = { ...obj, ...allPage };
            }
            else if (pcPage && pcPage.pageCode) {
                obj = { ...obj, ...pcPage };
            }
            else {
                Vue.prototype.$message.error('当前未绑定PC端页面，请检查设置！');
            }
        }
    }
    return obj;
}
function mount() {
    Object.entries(Filters).forEach(([k, v]) => {
        Vue.filter(k, v);
    });
    Vue.config.productionTip = false;
    // eslint-disable-next-line
    const vm = new Vue({
        // todo
        // i18n,
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
}
export async function startup() {
    mount();
}

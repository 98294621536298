import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let BackHeader = class BackHeader extends Vue {
    get title() {
        const routerName = this.$route.name;
        let title = '';
        switch (routerName) {
            case 'auth-config-set':
                title = '权限配置';
                break;
            case 'flow-center':
                title = '流程中心';
                break;
        }
        return title;
    }
    get appName() {
        return this.$route.query.appName;
    }
    goBack() {
        const routerName = this.$route.name;
        let backRouterName = '';
        switch (routerName) {
            case 'auth-config-set':
                backRouterName = 'auth-config';
                break;
            case 'flow-center':
                backRouterName = 'work-platform-home';
                break;
        }
        this.$router.push({
            name: backRouterName,
            query: {
                appName: this.appName,
            },
        });
    }
};
BackHeader = __decorate([
    Component({
        name: 'BackHeader',
        components: {
            Setting: () => import('@/components/global/setting'),
        },
    })
], BackHeader);
export default BackHeader;

export default {
  // 流程管理
  workflowManage: 'workflow-manage',

  // 流程委托
  workflowAgency: 'workflow-agency',

  // 流程异常日志
  workException: 'flow-exception',

  // 流程实例
  workflowInstance: 'workflow-manage-instance',
};

import { dd } from '@cloudpivot-hermes/nail-free-login';
import cookie from 'js-cookie';
/**
   * 防抖
   * @param fn
   * @param wait
   * @returns
   */
export const debounce = (fn, wait) => {
    let timer;
    let timeStamp = 0;
    let args;
    const run = () => {
        timer = setTimeout(() => {
            fn.apply(null, args);
        }, wait);
    };
    const clean = () => {
        clearTimeout(timer);
    };
    return function () {
        args = arguments;
        const now = (new Date()).getTime();
        if (now - timeStamp < wait) {
            clean();
            run();
        }
        else {
            run();
        }
        timeStamp = now;
    };
};
/**
 * 节流
 * @param fn
 * @param wait
 * @returns
 */
export const throttle = (fn, wait) => {
    let args;
    let loading = false;
    return function () {
        if (loading) {
            return;
        }
        args = arguments;
        loading = true;
        setTimeout(() => {
            fn.apply(null, args);
            loading = false;
        }, wait);
    };
};
/**
 * 深拷贝
 * @param data
 * @returns
 */
export const deepClone = (data) => {
    const result = Array.isArray(data) ? [] : {};
    if (!data)
        return data;
    for (const key in data) {
        // eslint-disable-next-line
        if (!data.hasOwnProperty(key))
            continue;
        if (data[key] && typeof data[key] === 'object') {
            result[key] = deepClone(data[key]);
        }
        else {
            result[key] = data[key];
        }
    }
    return result;
};
/**
 * 去除字符串前后空格
 * @param v
 * @returns
 */
export const trimStrFrontEnd = (v) => {
    return v.replace(/^(\s)|(\s)$/, '');
};
/**
 * 去除字符串前空格
 * @param v
 * @returns
 */
export const trimStrFront = (v) => {
    return v.replace(/^\s/, '');
};
/**
 * 去除字符串前空格
 * @param v
 * @returns
 */
export const trimStrEnd = (v) => {
    return v.replace(/\s$/, '');
};
/**
 * 去除字符串所有空格
 * @param v
 * @returns
 */
export const trimStr = (v) => {
    return v.replace(/\s/, '');
};
/**
 * 去掉属性字符串前后空格
 * @param originalData
 */
export const trim = (originalData, type = 'trimStrFrontEnd') => {
    forEachObject(originalData, (data, key, value) => {
        if (typeof value === 'string') {
            let method = () => { };
            if (type === 'trimStr') {
                method = trimStr;
            }
            else if (type === 'trimStrFrontEnd') {
                method = trimStrFrontEnd;
            }
            else if (type === 'trimStrFront') {
                method = trimStrFront;
            }
            else if (type === 'trimStrEnd') {
                method = trimStrEnd;
            }
            const replaceValue = method(value);
            data[key] = replaceValue;
        }
    });
};
/**
 * 递归遍历对象
 * @param data
 * @param callback
 * @returns
 */
export const forEachObject = (data, callback) => {
    if (!data)
        return data;
    const keys = Object.keys(data);
    for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        const value = data[key];
        if (value instanceof Object || value instanceof Array) {
            forEachObject(value, callback);
        }
        else {
            callback(data, key, value);
        }
    }
};
/**
 * 睡眠
 * @param time
 * @returns
 */
export const sleep = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
};
/**
 * 判断是否是PC
 */
export const isPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) >= 0) {
            flag = false;
            break;
        }
    }
    return flag;
};
/**
 * 判断是否是移动端
 */
export const isPhone = () => {
    // eslint-disable-next-line max-len
    const reg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
    const mobile = navigator.userAgent.match(reg);
    return mobile != null;
};
/**
 * 判断是否是safari浏览器
 * @returns
 */
export const isSafariBrowser = () => {
    const isSafari = /Safari/.test(navigator.userAgent);
    const isNotChrome = !/Chrome/.test(navigator.userAgent);
    return isSafari && isNotChrome;
};
/**
* 钉钉打开新窗口
*/
export const openDingTalkLink = (url) => {
    dd.biz.util.openLink({
        url,
    });
};
/**
 * 清空storage
 * @param store
 */
export const removeStorage = (store) => {
    cookie.remove('queryMsg');
    cookie.remove('expireTime');
    localStorage.removeItem('expireTime');
    localStorage.removeItem('token');
    localStorage.removeItem('corpInfo');
    localStorage.removeItem('publicUserInfo');
    localStorage.removeItem('user');
    localStorage.removeItem('appCode');
    localStorage.removeItem('locale');
    localStorage.removeItem('permList');
    localStorage.removeItem('isSystemManager');
    localStorage.removeItem('appPermList');
    localStorage.removeItem('isAllAppPerm');
    localStorage.removeItem('IS_FREELOGIN');
    localStorage.removeItem('default_key');
    store.commit('workPlatform/updateIsSetOrg', false);
    const storage = Object.keys(localStorage);
    storage.forEach(item => {
        if (item.indexOf('RECENT_APP_IDS') > -1) {
            localStorage.removeItem(item);
        }
    });
};
/**
   * 遍历组件树
   * @param comps
   * @param callback
   */
export const forEachComponet = (comps, callback) => {
    let isEnd = false;
    const forEach = (cs, c) => {
        for (const item of cs) {
            isEnd = c(item);
            if (isEnd) {
                break;
            }
            const children = item?.$children;
            if (children && children.length) {
                forEach(children, callback);
            }
        }
    };
    forEach(comps, callback);
};
/**
   * 获取指定名称的组件
   */
export const getChildComponentByName = (comps, name) => {
    return new Promise((resolve) => {
        const id = setInterval(() => {
            for (let i = 0, len = comps.length; i < len; i++) {
                const compItem = comps[i];
                const children = compItem.$children;
                if (compItem.$options?._componentTag === name) {
                    clearInterval(id);
                    resolve(compItem);
                }
                if (children && children.length) {
                    return getChildComponentByName(children, name);
                }
            }
        });
    });
};
/**
 * 从refs中获取指定的组件
 * @param refs
 * @param name
 * @returns
 */
export const getComponentByRefs = (refs, name) => {
    return new Promise((resolve) => {
        const id = setInterval(() => {
            if (refs[name]) {
                clearInterval(id);
                resolve(refs[name]);
            }
        });
    });
};
/**
 * 获取浏览器地址栏的参数
 * @param paraName
 * @returns
 */
export const getUrlParam = (paraName) => {
    var url = document.location.toString();
    var arrObj = url.split('?');
    if (arrObj.length > 1) {
        const arrPara = arrObj[1].split('&');
        let arr;
        for (let i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split('=');
            if (arr != null && arr[0] === paraName) {
                return arr[1];
            }
        }
        return '';
    }
    else {
        return '';
    }
};

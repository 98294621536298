import API, { commonErrHandler } from './config';
export class CommonServiceExtensionService {
    static async listExtensionJars() {
        const res = await API.Global.request({
            url: '/api/commonserviceextension/jar/v1/list',
            method: 'get',
        });
        return commonErrHandler(res, true);
    }
    static async delExtensionJar(param) {
        const res = await API.Global.request({
            url: `/api/commonserviceextension/jar/v1/uninstall?extensionJarId=${param}`,
            method: 'delete',
        });
        return commonErrHandler(res, true);
    }
    static async uploadExtensionJar(extensionJarId, uploadFile, progressClb) {
        const file = new FormData();
        file.append('file', uploadFile);
        return API.Global.request({
            url: `/api/commonserviceextension/jar/v1/install?extensionJarId=${extensionJarId}`,
            method: 'post',
            data: file,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressClb,
        });
    }
}

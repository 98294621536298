const AppAuth = {
    namespaced: true,
    state: {
        path: {},
        expandedDomainModelCodes: [],
    },
    mutations: {
        setPath(state, data) {
            state.path = data;
        },
        setExpandedDomainModelCodes(state, data) {
            state.expandedDomainModelCodes = data;
        },
    },
    actions: {},
    getters: {},
};
export default AppAuth;

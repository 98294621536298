import permCode from './perm-code';
class Perm {
    constructor() {
        this.permList = [];
        this.appPermList = [];
        this.getPermListFromLocal();
        const permTree = this.getPermTree(this.permList);
        console.log('权限树：', permTree);
    }
    /**
     * 从本地获取数据
     */
    getPermListFromLocal() {
        try {
            this.permList = JSON.parse(localStorage.getItem('permList')) || [];
        }
        catch (e) {
            // do nothing;
        }
    }
    /**
     * 从本地获取应用权限数据
     */
    getAppPermListFromLocal() {
        try {
            this.appPermList = JSON.parse(localStorage.getItem('appPermList')) || [];
        }
        catch (e) {
            // do nothing;
        }
    }
    /**
     * 从本地获取用户数据
     */
    getPublicUserInfoFromLocal() {
        try {
            return JSON.parse(localStorage.getItem('publicUserInfo')) || {};
        }
        catch (e) {
            // do nothing;
        }
    }
    /**
   * 生成权限树（调试）
   * @param allPermList
   */
    getPermTree(allPermList = []) {
        const getTree = (dataTree, parentId = '') => {
            const tree = [];
            let node = {};
            let children = [];
            if (!Array.isArray(dataTree) || !dataTree.length)
                return tree;
            for (let i = 0, len = dataTree.length; i < len; i++) {
                const item = dataTree[i];
                if (item.parentId === parentId) {
                    node = {
                        ...item,
                    };
                }
                else {
                    continue;
                }
                children = getTree(dataTree, item.id);
                node.children = children;
                tree.push(node);
            }
            return tree;
        };
        const result = getTree(allPermList);
        return result;
    }
    /**
     * 过滤没权限的导航数据
     * @param navigation
     * @returns
     */
    filterNavigation(navigation) {
        if (this.isSysAdmin()) {
            return navigation;
        }
        const result = navigation.filter((nav) => {
            return this.permList.some(perm => perm.code === nav.code);
        });
        ;
        return result;
    }
    /**
     * 过滤没权限的菜单数据
     * @param menus
     * @returns
     */
    filterMenus(menus) {
        // 超级管理员拥有绝对的权限
        if (this.isSuperAdmin()) {
            return menus;
        }
        // 系统管理员拥有除权限管理的权限
        if (this.isSysAdmin()) {
            return menus.filter(item => item.code !== 'manager-manage');
        }
        const result = [];
        for (const menu of menus) {
            if (!menu.children?.length) {
                if (this.permList.some(perm => perm.code === menu.code)) {
                    result.push(menu);
                }
                continue;
            }
            if (menu.children?.length) {
                const parent = {};
                for (const childMenu of menu.children) {
                    const childHasPerm = this.permList.some(perm => perm.code === childMenu.code);
                    if (childHasPerm) {
                        const resultHasParent = result.some(res => res.code === menu.code);
                        if (!resultHasParent) {
                            parent.name = menu.name;
                            parent.code = menu.code;
                            parent.routeName = menu.routeName;
                            parent.icon = menu.icon;
                            parent.children = [];
                            result.push(parent);
                        }
                        if (parent.children) {
                            parent.children.push(childMenu);
                        }
                    }
                }
            }
        }
        return result;
    }
    /**
     * 判断是否有权限code
     * @param code
     * @returns
     */
    hasCodePerm(code) {
        if (this.isSysAdmin())
            return true;
        const hasPerm = this.permList.some(item => item.code === code);
        return hasPerm;
    }
    /**
     * 判断是否有应用权限code
     * @param code
     * @returns
     */
    hasAppCodePerm(appCode) {
        const appPerm = this.appPermList.find(app => app.appCode === appCode);
        const permList = appPerm?.permList || [];
        const self = this;
        return function (code) {
            if (self.isSysAdmin())
                return true;
            if (self.getAllAppPermFromLocal())
                return true;
            const hasAppPerm = permList.some(item => item.code === code);
            ;
            return hasAppPerm;
        };
    }
    /**
     * 从本地获取系统管理员
     * @returns
     */
    getSysAdminFromLocal() {
        const isSystemManager = localStorage.getItem('isSystemManager');
        return !!isSystemManager && JSON.parse(isSystemManager);
    }
    /**
     * 从本地获取所有应用权限
     * @returns
     */
    getAllAppPermFromLocal() {
        const isAllAppPerm = localStorage.getItem('isAllAppPerm');
        return !!isAllAppPerm && JSON.parse(isAllAppPerm);
    }
    /**
     * 判断是否拥有所有应用权限
     * @returns
     */
    isAllAppPerm() {
        return this.getAllAppPermFromLocal();
    }
    /**
     * 判断是否系统管理员
     * @returns
     */
    isSysAdmin() {
        return this.getSysAdminFromLocal();
    }
    /**
     * 判断是否管理员
     * @returns
     */
    isAdmin() {
        if (this.isSysAdmin())
            return true;
        const res = this.permList.some((perm) => {
            return Perm.ADMIN_CODE.includes(perm.code);
        });
        return res;
    }
    /**
     * 判断是否超级管理员
     * @returns
     */
    isSuperAdmin() {
        const userInfo = this.getPublicUserInfoFromLocal() || {};
        const res = userInfo.username === 'admin';
        return res;
    }
    /**
     * 添加重定向
     * @param children
     * @returns
     */
    addRedirectToChildRoute(children) {
        const token = localStorage.getItem('token');
        if (!token) {
            return children;
        }
        if (this.isSysAdmin()) {
            return children;
        }
        // 删除固定默认的重定向
        if (children[0]?.redirect) {
            children.shift();
        }
        let newDedirect = {};
        for (const route of children) {
            const hasPerm = this.permList.some(perm => perm.code === route.meta?.code);
            if (hasPerm) {
                newDedirect = route;
                break;
            }
        }
        children.unshift({
            path: '/',
            redirect: {
                name: newDedirect.name,
            },
        });
        return children;
    }
}
Perm.ADMIN_CODE = [
    permCode.sysManage.sysManage,
    permCode.organization.organization,
    permCode.flowManage.workflowManage,
    permCode.appManage.appManage,
];
const perm = new Perm();
export default new Proxy(perm, {
    get(target, propKey) {
        if (typeof target[propKey] === 'function') {
            if (propKey !== 'getPermListFromLocal') {
                target.getPermListFromLocal();
            }
            if (propKey !== 'getSysAdminFromLocal') {
                target.getSysAdminFromLocal();
            }
            if (propKey !== 'getAppPermListFromLocal') {
                target.getAppPermListFromLocal();
            }
            if (propKey !== 'getAllAppPermFromLocal') {
                target.getAllAppPermFromLocal();
            }
        }
        return target[propKey];
    },
});

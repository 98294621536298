import ComponentMap from '../component-map';
import PermCode from '../perm-code';
import Perm from '@/router/permission';
const SystemManage = ComponentMap.systemManage;
const PermCodeModule = PermCode.sysManage;
export default () => {
    return [
        {
            path: '/system-manage',
            meta: {
                title: '云枢-系统管理',
                pagePermCode: PermCodeModule.sysManage,
            },
            component: SystemManage.systemManage,
            children: Perm.addRedirectToChildRoute([
                {
                    path: '/',
                    redirect: {
                        name: 'org-manage',
                    },
                },
                {
                    path: 'org-manage',
                    name: 'org-manage',
                    meta: {
                        title: '云枢-组织管理',
                        pagePermCode: PermCodeModule.orgManage,
                        code: PermCodeModule.orgManage,
                    },
                    component: SystemManage.orgManage,
                },
                {
                    path: 'sys-data-dictionary',
                    name: 'sys-data-dictionary',
                    meta: {
                        title: '云枢-数据字典',
                        pagePermCode: PermCodeModule.dataDictionary,
                        code: PermCodeModule.dataDictionary,
                    },
                    component: SystemManage.sysDataDictionary,
                },
                {
                    path: 'org-manage-detail/:id',
                    name: 'org-manage-detail',
                    meta: {
                        title: '云枢-组织配置详情',
                        pagePermCode: PermCodeModule.orgManage,
                    },
                    component: SystemManage.orgManageDetail,
                },
                {
                    path: 'perm-manage',
                    name: 'perm-manage',
                    meta: {
                        title: '云枢-权限管理',
                        pagePermCode: PermCodeModule.permManage,
                    },
                    component: SystemManage.permManage,
                },
                {
                    path: 'auth-manager',
                    name: 'auth-manager',
                    meta: {
                        title: '云枢-授权管理员',
                        pagePermCode: PermCodeModule.authManager,
                    },
                    component: SystemManage.authManager,
                },
                {
                    path: 'password-rule',
                    name: 'password-rule',
                    meta: {
                        title: '云枢-密码规则',
                        pagePermCode: PermCodeModule.passwordRule,
                        code: PermCodeModule.passwordRule,
                    },
                    component: SystemManage.passwordRule,
                },
                {
                    path: 'app-manage-log',
                    name: 'app-manage-log',
                    meta: {
                        title: '云枢-应用管理日志',
                        pagePermCode: PermCodeModule.appManageLog,
                        code: PermCodeModule.appManageLog,
                    },
                    component: SystemManage.appManageLog,
                },
                {
                    path: 'app-operate-log',
                    name: 'app-operate-log',
                    meta: {
                        title: '云枢-应用操作日志',
                        pagePermCode: PermCodeModule.appOperateLog,
                        code: PermCodeModule.appOperateLog,
                    },
                    component: SystemManage.appOperateLog,
                },
                {
                    path: 'org-permission-log',
                    name: 'org-permission-log',
                    meta: {
                        title: '云枢-组织权限日志',
                        pagePermCode: PermCodeModule.orgPermManageLog,
                        code: PermCodeModule.orgPermManageLog,
                    },
                    component: SystemManage.orgPermissionLog,
                },
                {
                    path: 'login-log',
                    name: 'login-log',
                    meta: {
                        title: '云枢-登录日志',
                        pagePermCode: PermCodeModule.loginLog,
                        code: PermCodeModule.loginLog,
                    },
                    component: SystemManage.loginLog,
                },
                {
                    path: 'password-update-log',
                    name: 'password-update-log',
                    meta: {
                        title: '云枢-密码修改日志',
                        pagePermCode: PermCodeModule.passwordUpdateLog,
                        code: PermCodeModule.passwordUpdateLog,
                    },
                    component: SystemManage.passwordUpdateLog,
                },
                {
                    path: 'common-service-extension',
                    name: 'common-service-extension',
                    meta: {
                        title: '云枢-公共服务扩展',
                        pagePermCode: PermCodeModule.commonServiceExtension,
                        code: PermCodeModule.commonServiceExtension,
                    },
                    component: SystemManage.commonServiceExtension,
                },
            ]),
        },
    ];
};

import ComponentMap from '../component-map';
import PermCode from '../perm-code';
import Perm from '@/router/permission';
const FlowManage = ComponentMap.flowManage;
const PermCodeModule = PermCode.flowManage;
export default () => {
    return [
        {
            path: '/flow-manage',
            meta: {
                title: '云枢-流程管理',
                pagePermCode: PermCodeModule.workflowManage,
            },
            component: FlowManage.flowManage,
            children: Perm.addRedirectToChildRoute([
                {
                    path: '/',
                    redirect: {
                        name: 'flow-instance',
                    },
                },
                {
                    path: 'flow-instance',
                    name: 'flow-instance',
                    meta: {
                        title: '云枢-流程实例',
                        pagePermCode: PermCodeModule.workflowInstance,
                        code: PermCodeModule.workflowInstance,
                    },
                    component: FlowManage.flowInstance,
                },
                {
                    path: 'flow-entrust',
                    name: 'flow-entrust',
                    meta: {
                        title: '云枢-流程委托',
                        pagePermCode: PermCodeModule.workflowAgency,
                        code: PermCodeModule.workflowAgency,
                    },
                    component: FlowManage.flowEntrust,
                },
                {
                    path: 'flow-exception',
                    name: 'flow-exception',
                    meta: {
                        title: '云枢-流程异常日志',
                        pagePermCode: PermCodeModule.workException,
                        code: PermCodeModule.workException,
                    },
                    component: FlowManage.flowException,
                },
            ]),
        },
    ];
};

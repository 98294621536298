import Vue from 'vue';
import Vuex from 'vuex';
import workPlatform from './modules/work-platform';
import AppAuth from './modules/app-auth';
import Global from './modules/global';
import Organization from './modules/organization';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        workPlatform,
        AppAuth,
        Global,
        Organization,
    },
});
